import { SpecificationRequestModel } from './specification.requestModel';
import { IBaseState } from '../base/baseState.interface';
import { ISpecification } from './specification.interface';

export const requestModel = (): typeof SpecificationRequestModel => {
    return SpecificationRequestModel;
};

export const getBySpecificationGroup =
    (state: IBaseState<ISpecification>) =>
    (id: number): ISpecification[] => {
        return state.entities.local.filter((entity) => entity?.specification_group_id === id);
    };
