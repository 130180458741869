import { Store } from 'vuex';
import { IProduct } from '../product/product.interface';
import { ICartState } from './cartState.interface';
import { ICartItem } from './cartItem.interface';

export class CartModel<S> {
    protected _store: Store<S>;

    public constructor(store: Store<S>) {
        this._store = store;
    }

    public get cartItems(): ICartItem[] {
        return this._store.getters[`cart/getCartItems`];
    }

    public addItem(payload: { product: IProduct; quantity: number }): Promise<void> {
        return this._store.dispatch('cart/addItem', payload);
    }

    public resetState(newCartState: ICartState) {
        return this._store.dispatch('cart/resetState', newCartState);
    }

    public removeItem(cartItem: ICartItem) {
        return this._store.dispatch('cart/removeItem', cartItem);
    }

    public setQuantity(payload: { cartItem: ICartItem; quantity: number }) {
        return this._store.dispatch('cart/setQuantity', payload);
    }
}
