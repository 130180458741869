import { ICartState } from './cartState.interface';
import { ICartItem } from './cartItem.interface';
import { CartRequestModel } from './cart.requestModel';

export const requestModel = (): typeof CartRequestModel => {
    return CartRequestModel;
};

export const getCartItems = (state: ICartState): ICartItem[] => {
    return state.cartItems;
};

export const getOrderId = (state: ICartState): number => {
    return state.orderId;
};
