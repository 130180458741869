




















import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';

@Component
export default class VueCartPreview extends BaseComponent {
    @Prop({
        type: Object,
    })
    user: Record<string, unknown>;

    public get cartPositions() {
        return this.models.cart.cartItems;
    }
    public sumCart() {
        return this.cartPositions.reduce((prev, cur) => prev + cur.net_price * cur.quantity, 0);
    }
}
