import { CancelTokenSource } from 'axios';

export interface ISearchResultPropertiesState {
    availableFilters: IAvailableFilters;
    categoryId: number;
    currentPage: number;
    currentFilters: ICurrentFilters;
    lastPage: number;
    totalProducts: number;
    productIds: { [key: string]: number[] };
    activeRequest: CancelTokenSource;
    isNew: boolean;
    queryParamsString: string;
}

export interface ICurrentFilters {
    brand: number[];
    colour: number[];
    fuel_type: number[];
    gender: number[];
    has_stock: number[];
    material: number[];
    size: number[];
    specification: number[];
    search: string;
}

export interface IAvailableFilters {
    brand: number[];
    gender: number[];
    colour: number[];
    has_stock: number[];
    material: number[];
    size: number[];
    fuel_type: number[];
    specification: { [key: string]: { matchcode: string; values: { [key: string]: string } } };
}

export const baseFilters = [
    'brand',
    'colour',
    'fuel_type',
    'gender',
    'has_stock',
    'size',
    'material',
    'specification',
] as const;
export type BaseFilters = typeof baseFilters[number];
