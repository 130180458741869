




import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';
import { ISearchResultPropertiesBackend } from '../../store/searchResultProperties/searchResultPropertiesBackend.interface';
import { baseFilters } from '../../store/searchResultProperties/searchResultPropertiesState.interface';

@Component
export default class VueStoreFillSearchResults extends BaseComponent {
    @Prop({
        type: Object,
    })
    results: ISearchResultPropertiesBackend;

    public created() {
        if (this.results) {
            if (this.getQueryParamsFromUrl() === this.getQueryPramsStringByResult(this.results)) {
                this.models.searchResultProperties.updateState(this.results);
                return;
            }

            if (this.getQueryParamsFromUrl() === this.getQueryPramsStringInStorage()) {
                const valueString = window.localStorage.getItem('searchResultStore');
                const state = JSON.parse(valueString);
                this.models.searchResultProperties.updateState(state);
                return;
            }

            this.models.searchResultProperties.updateState(this.results);
        }
    }

    public getQueryPramsStringByResult(results: ISearchResultPropertiesBackend) {
        const activePath = window.location.pathname;
        const activeOrigin = window.location.origin;
        const url = new URL(activeOrigin + activePath);

        for (const baseFilter of baseFilters) {
            if (!Array.isArray(results.currentFilters[baseFilter])) {
                continue;
            }
            results.currentFilters[baseFilter].forEach((valueId) => {
                url.searchParams.append(baseFilter + '[]', valueId.toString(10));
            });
        }

        if (results.currentFilters.search) {
            url.searchParams.append('search', results.currentFilters.search);
        }

        if (results.currentPage > 1) {
            url.searchParams.append('page', results.currentPage.toString());
        }

        return url.search;
    }

    public getQueryPramsStringInStorage() {
        const valueString = window.localStorage.getItem('searchResultStore');
        if (!valueString) {
            return null;
        }
        const state = JSON.parse(valueString);

        return state.queryParamsString;
    }

    public getQueryParamsFromUrl() {
        return window.location.search;
    }

    public getStateFromStorage() {
        const valueString = window.localStorage.getItem('searchResultStore');
        if (!valueString) {
            return null;
        }

        return JSON.parse(valueString);
    }
}
