





































import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';

@Component
export default class VuePagination extends BaseComponent {
    @Prop({
        type: Number,
    })
    public totPages: number;

    @Prop({
        type: Number,
    })
    public selectedPage: number;

    @Prop({ type: Number, default: 2 })
    public amountSurroundingPages: number;

    public get startPrecedingPages(): number {
        let start = this.selectedPage - this.amountSurroundingPages;
        if (start < 1) {
            start = 1;
        }

        return start;
    }

    public get endSucceedingPages(): number {
        let end = this.selectedPage + this.amountSurroundingPages;

        if (end > this.totPages) {
            end = this.totPages;
        }

        return end;
    }

    public get precedingPages(): number[] {
        const precedingPages: number[] = [];

        if (this.selectedPage === 1) {
            return precedingPages;
        }

        for (let i = this.startPrecedingPages; i < this.selectedPage; i++) {
            precedingPages.push(i);
        }
        return precedingPages;
    }

    public get succeedingPages(): number[] {
        const succeedingPages: number[] = [];
        if (this.selectedPage === this.totPages) {
            return succeedingPages;
        }

        for (let i = this.selectedPage + 1; i <= this.endSucceedingPages; i++) {
            succeedingPages.push(i);
        }
        return succeedingPages;
    }

    @Emit()
    public goForward() {
        return;
    }

    @Emit()
    public goBack() {
        return;
    }

    @Emit()
    public goToPage(page: number): number {
        return page;
    }
}
