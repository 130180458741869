




















import { Component, Emit, Model, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';
import { FieldControl } from '../../helpers/validation/fieldControl';
import { ValidationRule } from '../../helpers/validation/rules/validationRule';
import { parseValidationRules } from '../../helpers/validation/rules/parseValidationRules';
import tippy, { Instance as TippyInstance } from 'tippy.js';

@Component
export default class VueCheckbox extends BaseComponent {
    fieldControl: FieldControl = null;
    isDirty = false;
    isTouched = false;
    initialError: string = null;
    tippy: TippyInstance = null;

    @Prop({
        type: String,
        default: '',
    })
    validationRules: string;

    @Prop({
        type: String,
    })
    name: string;

    @Prop({
        type: String,
    })
    id: string;

    @Prop({
        type: String,
    })
    label: string;

    @Prop({
        type: String,
    })
    initError: string;

    @Prop({
        type: Boolean,
    })
    required: boolean;

    @Prop({
        type: Boolean,
    })
    disabled: boolean;

    @Prop({
        type: String,
    })
    tooltipp: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    blade: boolean;

    @Prop({
        type: [Boolean, Number],
    })
    initValue: boolean | number;

    @Prop({
        type: [Boolean, String, Number],
        default: 1,
    })
    valueId: boolean | string | number;

    @Model('input', {
        type: [Boolean, String, Number],
    })
    value: boolean | string | number;

    internalValue: boolean = false;

    public get requiredLabel() {
        return this.label + (this.required ? ' *' : '');
    }

    toggle() {
        if (this.disabled) {
            return;
        }
        this.isTouched = true;
        this.internalValue = !this.internalValue;
        this.initialError = null;
        this.validate();
        this.$emit('input', this.internalValue);
        this.blur();
    }

    @Emit()
    blur() {
        return this.internalValue;
    }

    created(): void {
        this.initialError = this.initError;
        if (this.blade) {
            this.setInternalValue(this.initValue);
        } else {
            this.setInternalValue(this.validate);
        }
        this.fieldControl = new FieldControl(this.name);
        this.loadValidationRules();
        this.validate();
    }

    beforeUpdate() {
        if (this.blade) {
            return;
        }

        this.setInternalValue(this.value);
    }

    mounted(): void {
        if (this.tooltipp && !this.tippy && this.$refs.tooltipp instanceof Element) {
            this.tippy = tippy(this.$refs.tooltipp);
            this.tippy.setContent(this.tooltipp);
        }
    }

    get listeners() {
        const { input, blur, ...listeners } = this.$listeners;
        return listeners;
    }

    private validate() {
        this.fieldControl.validate(this.internalValue);
        this.isDirty = true;
    }

    get showError() {
        return !!this.initialError || (!this.fieldControl.isValid && this.isTouched);
    }

    private setInternalValue(value: any) {
        if (typeof value === 'undefined') {
            this.internalValue = false;
            return;
        }

        if (typeof value === 'string') {
            if (value === '0' || value.toLowerCase() === 'false') {
                this.internalValue = false;
                return;
            }

            this.internalValue = Boolean(value);
            return;
        }

        if (typeof value === 'boolean') {
            this.internalValue = value;
            return;
        }

        if (typeof value === 'number') {
            this.internalValue = !!this.value;
            return;
        }

        this.internalValue = !!this.value;
    }

    loadValidationRules() {
        const validations: ValidationRule[] = [];
        const createValidationRuleOptions = parseValidationRules(this.validationRules);

        for (const createValidationRuleOption of createValidationRuleOptions) {
            const validation = ValidationRule.create(createValidationRuleOption);
            if (!validation) {
                continue;
            }
            validations.push(validation);
        }

        if (this.required) {
            validations.push(ValidationRule.required);
        }

        this.fieldControl.addValidationRule(...validations);
    }
}
