














import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../../base/baseComponent';
import VueFilterIcon from './vueFilterIcon.vue';
import { IMaterial } from '../../../store/material.interface';

@Component({
    components: { VueFilterIcon },
})
export default class VueMaterialFilter extends BaseComponent {
    @Prop({ type: Array })
    public materials: IMaterial[];

    public get possibleMaterialIds() {
        return this.models.searchResultProperties.availableFilters.material;
    }

    public get possibleMaterials() {
        return this.materials.filter((material) => this.possibleMaterialIds.includes(material.id));
    }

    public selectMaterial(id: number) {
        this.models.searchResultProperties.toggleBaseFilter({ id, baseFilter: 'material' });
    }

    public materialFilterIsSet(id: number) {
        return this.models.searchResultProperties.baseFilterIsSet({ id, baseFilter: 'material' });
    }
}
