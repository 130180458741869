import { Store } from 'vuex';
import { EntityModel } from '../base/baseEntity.model';
import { IBaseState } from '../base/baseState.interface';
import { CategoryRequestModel } from './category.requestModel';
import { ICategory } from './category.interface';

export class CategoryModel extends EntityModel<IBaseState<ICategory>, typeof CategoryRequestModel, ICategory> {
    constructor(store: Store<IBaseState<ICategory>>) {
        super('category', store, CategoryRequestModel);
    }

    public getChildren(parentId: number) {
        return this._store.getters[`${this._modelName}/getChildren`](parentId);
    }
}
