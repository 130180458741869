











import { Component } from 'vue-property-decorator';
import BaseComponent from '../../base/baseComponent';
import VueFilterIcon from './vueFilterIcon.vue';

@Component({
    components: { VueFilterIcon },
})
export default class VueStockFilter extends BaseComponent {
    public stocks = [0, 1];

    public get possibleStocks() {
        return this.models.searchResultProperties.availableFilters.has_stock;
    }

    public selectStock(id: number) {
        this.models.searchResultProperties.toggleBaseFilter({ id, baseFilter: 'has_stock' });
    }

    public stockFilterIsSet(id: number) {
        return this.models.searchResultProperties.baseFilterIsSet({ id, baseFilter: 'has_stock' });
    }

    public show(): boolean {
        if (!this.possibleStocks.length) {
            return false;
        }

        if (this.possibleStocks.length > 1) {
            return true;
        }

        return this.stockFilterIsSet(this.possibleStocks[0]);
    }
}
