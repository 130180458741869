import { ActionContext } from 'vuex';
import { BaseFilters, ICurrentFilters, ISearchResultPropertiesState } from './searchResultPropertiesState.interface';
import { ISearchResultPropertiesBackend } from './searchResultPropertiesBackend.interface';
import { SearchResultPropertiesRequestModel } from './searchResultProperties.requestModel';

export const update = (
    context: ActionContext<ISearchResultPropertiesState, any>,
    payload: ISearchResultPropertiesBackend
) => {
    console.log('action update dispatched');
    for (const product of payload.products) {
        context.commit('product/add', product, { root: true });
    }
    return context.commit('update', payload);
};

export const setCurrentPage = (context: ActionContext<ISearchResultPropertiesState, any>, page: number) => {
    context.dispatch('loadPage', page);

    if (page < context.getters.getLastPageNumber) {
        context.dispatch('loadPage', page + 1);
    }
    if (page > 1) {
        context.dispatch('loadPage', page - 1);
    }
    context.commit('setCurrentPage', page);
    context.commit('setQueryParamsOnUrl');
    return context.commit('storeToStorage', context.rootGetters['product/all']);
};

export const toggleBaseFilter = (
    context: ActionContext<ISearchResultPropertiesState, any>,
    payload: { id: number; baseFilter: BaseFilters }
) => {
    context.commit('resetResults');
    context.commit('toggleBaseFilter', payload);
    return context.dispatch('search');
};

export const search = (context: ActionContext<ISearchResultPropertiesState, any>) => {
    const RequestModel = context.getters.requestModel as typeof SearchResultPropertiesRequestModel;

    const currentFilters = context.getters.currentFilters as ICurrentFilters;

    context.commit('setQueryParamsOnUrl');

    const allParams = { ...currentFilters, category_id: context.getters.getCategoryId };

    window.scrollTo(0, 0);

    RequestModel.search(allParams)
        .then((searchResults) => {
            for (const product of searchResults.products) {
                context.commit('product/add', product, { root: true });
            }
            context.commit('update', searchResults);
            return context.commit('storeToStorage', context.rootGetters['product/all']);
        })
        .catch(() => {
            // todo rest
        });
};

export const loadPage = (context: ActionContext<ISearchResultPropertiesState, any>, page: number) => {
    if (context.getters.getProductIdsByPage(page).length) {
        return;
    }

    const RequestModel = context.getters.requestModel as typeof SearchResultPropertiesRequestModel;

    const currentFilters = context.getters.currentFilters;

    const allParams = { ...currentFilters, category_id: context.getters.getCategoryId, page };

    return RequestModel.search(allParams)
        .then((searchResults) => {
            for (const product of searchResults.products) {
                context.commit('product/add', product, { root: true });
            }
            context.commit('addPage', searchResults);
            return context.commit('storeToStorage', context.rootGetters['product/all']);
        })
        .catch(() => {
            // todo rest
        });
};
