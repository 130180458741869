














import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../../base/baseComponent';
import VueFilterIcon from './vueFilterIcon.vue';
import { IFuelType } from '../../../store/fuelType.interface';

@Component({
    components: { VueFilterIcon },
})
export default class VueFuelTypeFilter extends BaseComponent {
    @Prop({ type: Array })
    public fuelTypes: IFuelType[];

    public get possibleFuelTypeIds() {
        return this.models.searchResultProperties.availableFilters.fuel_type;
    }

    public get possibleFuelTypes() {
        return this.fuelTypes.filter((fuelType) => this.possibleFuelTypeIds.includes(fuelType.id));
    }

    public selectFuelType(id: number) {
        this.models.searchResultProperties.toggleBaseFilter({ id, baseFilter: 'fuel_type' });
    }

    public fuelTypeFilterIsSet(id: number) {
        return this.models.searchResultProperties.baseFilterIsSet({ id, baseFilter: 'fuel_type' });
    }
}
