import axios, { AxiosError } from 'axios';
import Swal from 'sweetalert2';
import { ISearchResultPropertiesBackend } from './searchResultPropertiesBackend.interface';

export class SearchResultPropertiesRequestModel {
    protected static baseRoute: string = 'products';

    public static search(params: any): Promise<ISearchResultPropertiesBackend> {
        return new Promise((resolve, reject) => {
            axios
                .get<ISearchResultPropertiesBackend>(`/webapi/${this.baseRoute}`, { params })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((reason: AxiosError<{ message: string }>) => {
                    Swal.fire({
                        html: reason.response.data.message,
                        icon: 'error',
                    });
                    reject(reason.response.data.message);
                });
        });
    }
}
