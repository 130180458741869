import { Store } from 'vuex';
import { EntityModel } from '../base/baseEntity.model';
import { IBaseState } from '../base/baseState.interface';
import { SpecificationGroupRequestModel } from './specificationGroup.requestModel';
import { ISpecificationGroup } from './specificationGroup.interface';

export class SpecificationGroupModel extends EntityModel<
    IBaseState<ISpecificationGroup>,
    typeof SpecificationGroupRequestModel,
    ISpecificationGroup
> {
    constructor(store: Store<IBaseState<ISpecificationGroup>>) {
        super('specifications', store, SpecificationGroupRequestModel);
    }
}
