import axios, { AxiosError } from 'axios';
import Swal from 'sweetalert2';
import { IProduct } from '../product/product.interface';
import { ICartState } from './cartState.interface';
import { ICartItem } from './cartItem.interface';
import { Toast } from '../../helpers/toast';

export class CartRequestModel {
    public static post(order_id: number, product: IProduct, quantity: number = 1): Promise<ICartState> {
        return axios
            .post<ICartState>(`/webapi/positions`, { order_id, product_id: product.id, quantity })
            .then((res) => {
                return res.data;
            })
            .catch((reason: AxiosError<{ message: string }>) => {
                Swal.fire({
                    html: reason.response.data.message,
                    icon: 'error',
                });
                throw new Error(reason.response.data.message);
            });
    }

    public static getState(): Promise<ICartState> {
        return axios
            .get<ICartState>(`/webapi/cart`)
            .then((res) => {
                return res.data;
            })
            .catch((reason: AxiosError<{ message: string }>) => {
                Swal.fire({
                    html: reason.response.data.message,
                    icon: 'error',
                });
                throw new Error(reason.response.data.message);
            });
    }

    public static setQuantity(cartPosition: ICartItem): Promise<ICartState> {
        return axios
            .patch<ICartState>(`/webapi/positions/${cartPosition.id}`, { quantity: cartPosition.quantity })
            .then((res) => {
                return res.data;
            })
            .catch((reason: AxiosError<{ message: string }>) => {
                Swal.fire({
                    html: reason.response.data.message,
                    icon: 'error',
                });
                throw new Error(reason.response.data.message);
            });
    }

    public static removeItem(cartPosition: ICartItem) {
        return axios
            .delete<ICartState>(`/webapi/positions/${cartPosition.id}`, {})
            .then((res) => {
                Toast.fire({
                    icon: 'success',
                    title: 'Erfolgreich aus Warenkorb gelöscht',
                });
                return res.data;
            })
            .catch((reason: AxiosError<{ message: string }>) => {
                Swal.fire({
                    html: reason.response.data.message,
                    icon: 'error',
                });
                throw new Error(reason.response.data.message);
            });
    }
}
