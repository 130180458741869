import { CategoryRequestModel } from './category.requestModel';
import { IBaseState } from '../base/baseState.interface';
import { ICategory } from './category.interface';

export const requestModel = (): typeof CategoryRequestModel => {
    return CategoryRequestModel;
};

export const getChildren =
    (state: IBaseState<ICategory>) =>
    (id: number): ICategory[] => {
        return state.entities.local.filter((entity) => entity?.parent_id === id);
    };
