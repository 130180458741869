


















import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';
import { ICategory } from '../../store/category/category.interface';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Toast } from '../../helpers/toast';

@Component
export default class VueCategorySortItem extends BaseComponent {
    @Prop({
        type: Object,
    })
    public category: ICategory;

    @Emit()
    public up() {
        axios
            .patch<ICategory>(`/webapi/categories/categorySort/${this.category.id}/categoryUp`)
            .then((response) => {
                Toast.fire({
                    icon: 'success',
                    title: 'Category has been moved!',
                });
            })
            .catch((response) => {
                Swal.fire({
                    text: "Error! Category hasn't been moved.",
                    icon: 'error',
                });
            });
        return;
    }

    @Emit()
    public down() {
        axios
            .patch<ICategory>(`/webapi/categories/categorySort/${this.category.id}/categoryDown`)
            .then((response) => {
                Toast.fire({
                    icon: 'success',
                    title: 'Category has been moved!',
                });
            })
            .catch((response) => {
                Swal.fire({
                    text: "Error! Category hasn't been moved.",
                    icon: 'error',
                });
            });
        return;
    }
}
