import { ICartState } from './cartState.interface';
import { IProduct } from '../product/product.interface';
import { ICartItem } from './cartItem.interface';

export const resetState = (state: ICartState, payload: ICartState) => {
    if (!payload) {
        return;
    }
    state.cartItems = payload.cartItems;
    state.orderId = payload.orderId;
};

export const removeItem = (state: ICartState, cartItem: ICartItem) => {
    const index = state.cartItems.findIndex((item) => item.id === cartItem.id);

    if (index > -1) {
        state.cartItems.splice(index, 1);
    }
};

export const setQuantity = (state: ICartState, payload: { cartItem: ICartItem; quantity: number }) => {
    const item = state.cartItems.find((item) => item.id === payload.cartItem.id);

    if (item) {
        item.quantity = payload.quantity;
    }
};

export const addItem = (state: ICartState, payload: { product: IProduct; quantity: number }) => {
    const item = state.cartItems.find((item) => item.product_id === payload.product.id);
    if (item) {
        item.quantity += payload.quantity;
        return;
    }
    let lastIndex = 0;

    if (state.cartItems.length) {
        lastIndex = Math.max(...state.cartItems.map((item) => item.index));
    }

    const newItem: ICartItem = {
        id: 0,
        quantity: payload.quantity,
        product_id: payload.product.id,
        number: payload.product.number,
        index: ++lastIndex,
        description: payload.product.description,
        hazardous: payload.product.hazardous,
        UPC: payload.product.UPC,
        net_price: payload.product.net_price,
        name: payload.product.name,
        tax_rate_id: payload.product.tax_rate_id,
        weight: payload.product.shipping_weight,
        main_image_url: '',
        netsuite_id: payload.product.netsuite_id,
        brand_name: payload.product.brand_name,
    };

    state.cartItems.push(newItem);
};
