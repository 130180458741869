
























import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';
import VueProductPreview from './vueProductPreview.vue';
import VuePagination from './vuePagination.vue';
import VueSpinner from '../search/vueSpinner.vue';
import { CancelTokenSource } from 'axios';

@Component({
    components: { VueSpinner, VuePagination, VueProductPreview },
})
export default class VueProductPreviewCollection extends BaseComponent {
    @Prop({
        type: Object,
    })
    user: Record<string, unknown>;

    public get productIds(): number[] {
        return this.models.searchResultProperties.getProductIdsByPage(this.currentPage);
        // return this.models.product.all.map( product => product.id );
    }

    public get totProducts(): number {
        return this.models.searchResultProperties.totalProducts;
    }

    public get currentPage(): number {
        return this.models.searchResultProperties.currentPageNumber;
    }

    public get lastPageNumber() {
        return this.models.searchResultProperties.lastPageNumber;
    }

    public get activeRequest(): CancelTokenSource {
        return this.models.searchResultProperties.activeRequest;
    }

    public goForward() {
        this.models.searchResultProperties.setCurrentPage(this.currentPage + 1);
        window.scrollTo(0, 0);
    }

    public goBack() {
        this.models.searchResultProperties.setCurrentPage(this.currentPage - 1);
        window.scrollTo(0, 0);
    }

    public goToPage(page: number) {
        this.models.searchResultProperties.setCurrentPage(page);
        window.scrollTo(0, 0);
    }
}
