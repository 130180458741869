import * as getters from './specificationGroup.getters';
import * as baseGetters from '../base/base.getters';
import * as baseMutations from '../base/base.mutations';
import * as baseActions from '../base/base.actions';
import { state } from '../base/base.state';

export const specificationGroupStore = {
    namespaced: true,
    state,
    getters: { ...baseGetters, ...getters },
    actions: { ...baseActions },
    mutations: { ...baseMutations },
};
