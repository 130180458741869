











import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';
import { IProduct } from '../../store/product/product.interface';
import Swal from 'sweetalert2';
import { Toast } from '../../helpers/toast';

@Component
export default class VueBuySmall extends BaseComponent {
    @Prop({
        type: Object,
    })
    product: IProduct;

    public quantity: number = 0;

    public created(): void {
        this.quantity = this.product.packing_unit;
    }

    public addItemToCart() {
        if (this.product) {
            this.models.cart
                .addItem({
                    product: this.product,
                    quantity: this.quantity,
                })
                .then((response) => {
                    Toast.fire({
                        icon: 'success',
                        title: this.lang('l.productAddedToCart'),
                    });
                })
                .catch((error: Error) => {
                    Swal.fire({
                        icon: 'error',
                        text: this.lang('errors.position.quantity_too_high'),
                    });
                });
        }
    }
}
