
































import BaseComponent from '../base/baseComponent';
import { Component, Emit, Model, Prop } from 'vue-property-decorator';
import tippy, { Instance as TippyInstance } from 'tippy.js';
import { FieldControl } from '../../helpers/validation/fieldControl';
import { ValidationRule } from '../../helpers/validation/rules/validationRule';
import { parseValidationRules } from '../../helpers/validation/rules/parseValidationRules';

@Component
export default class VueInput extends BaseComponent {
    fieldControl: FieldControl = null;
    isDirty = false;
    isTouched = false;
    initialError: string = null;
    tippy: TippyInstance = null;

    @Prop({
        type: String,
        default: '',
    })
    validationRules: string;

    @Prop({
        type: String,
    })
    name: string;

    @Prop({
        type: String,
    })
    id: string;

    @Prop({
        type: String,
    })
    label: string;

    @Prop({
        type: String,
    })
    initError: string;

    @Prop({
        type: Boolean,
    })
    required: boolean;

    @Prop({
        type: Boolean,
    })
    disabled: boolean;

    @Prop({ type: String, default: 'text' })
    type: string;

    @Prop({
        type: String,
    })
    tooltipp: string;

    @Prop({
        type: String,
    })
    maxlength: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    blade: boolean;

    @Prop({
        type: String,
    })
    initValue: string;

    @Model('input')
    value: string;

    internValue = '';

    get listeners() {
        const { input, blur, ...listeners } = this.$listeners;
        return listeners;
    }

    get showError() {
        return !!this.initialError || (!this.fieldControl.isValid && this.isTouched);
    }

    input(ev: any) {
        this.internValue = ev.target.value;
        this.initialError = null;
        this.validate();
        this.$emit('input', ev.target.value);
    }

    @Emit()
    blur(ev: any) {
        this.isTouched = true;
        this.validate();
        return ev;
    }

    created(): void {
        this.initialError = this.initError;
        if (this.blade) {
            this.internValue = this.initValue;
        } else {
            this.internValue = this.value;
        }

        this.fieldControl = new FieldControl(this.name);
        this.loadValidationRules();
        this.validate();
    }

    beforeUpdate() {
        if (this.blade) {
            return;
        }
        this.internValue = this.value;
    }

    mounted(): void {
        if (this.tooltipp && !this.tippy && this.$refs.tooltipp instanceof Element) {
            this.tippy = tippy(this.$refs.tooltipp);
            this.tippy.setContent(this.tooltipp);
        }
    }

    loadValidationRules() {
        const validations: ValidationRule[] = [];
        const createValidationRuleOptions = parseValidationRules(this.validationRules);

        for (const createValidationRuleOption of createValidationRuleOptions) {
            const validation = ValidationRule.create(createValidationRuleOption);
            if (!validation) {
                continue;
            }
            validations.push(validation);
        }

        if (this.required) {
            validations.push(ValidationRule.required);
        }

        this.fieldControl.addValidationRule(...validations);
    }

    private validate() {
        this.fieldControl.validate(this.internValue);
        this.isDirty = true;
    }
}
