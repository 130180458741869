










import { Component, Prop } from 'vue-property-decorator';
import { IBrand } from '../../../store/brand.interface';
import BaseComponent from '../../base/baseComponent';
import VueFilterIcon from './vueFilterIcon.vue';

@Component({
    components: { VueFilterIcon },
})
export default class VueBrandFilter extends BaseComponent {
    @Prop({ type: Array })
    public brands: IBrand[];

    public get possibleBrandIds() {
        return this.models.searchResultProperties.availableFilters.brand;
    }

    public get possibleBrands() {
        return this.brands
            .filter((brand) => this.possibleBrandIds.includes(brand.id))
            .sort((a, b) => a.sort_order - b.sort_order);
    }

    public selectBrand(id: number) {
        this.models.searchResultProperties.toggleBaseFilter({ id, baseFilter: 'brand' });
    }

    public brandFilterIsSet(id: number) {
        return this.models.searchResultProperties.baseFilterIsSet({ id, baseFilter: 'brand' });
    }

    public show(): boolean {
        if (!this.possibleBrandIds.length) {
            return false;
        }

        if (this.possibleBrandIds.length > 1) {
            return true;
        }

        return this.brandFilterIsSet(this.possibleBrandIds[0]);
    }
}
