import Vue from 'vue';
import Component from 'vue-class-component';
import { TransLation } from '../../helpers/translation';
import { CategoryModel } from '../../store/category/category.model';
import { ProductModel } from '../../store/product/product.model';
import { SearchResultPropertiesModel } from '../../store/searchResultProperties/searchResultProperties.model';
import { CartModel } from '../../store/cart/cart.model';
import { SpecificationModel } from '../../store/Specification/specification.model';
import { SpecificationGroupModel } from '../../store/SpecificationGroup/specificationGroup.model';

// Define a super class component
@Component
export default class BaseComponent extends Vue {
    public models = {
        category: new CategoryModel(this.$store),
        product: new ProductModel(this.$store),
        searchResultProperties: new SearchResultPropertiesModel(this.$store),
        cart: new CartModel(this.$store),
        specification: new SpecificationModel(this.$store),
        specificationGroup: new SpecificationGroupModel(this.$store),
    };
    private readonly translation = TransLation;

    public lang(name: string, attributes?: { [key: string]: string }) {
        return this.translation.get(name, attributes);
    }
}
