







































import { Component } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';
@Component
export default class VueOverlay extends BaseComponent {
    public openModal = false;

    public open() {
        this.openModal = true;
        document.body.classList.add('modal-open');
    }
    public close() {
        this.openModal = false;
        document.body.classList.remove('modal-open');
    }
}
