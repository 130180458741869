import { state } from './searchResultProperties.state';
import * as getters from './searchResultProperties.getters';
import * as mutations from './searchResultProperties.mutations';
import * as actions from './searchResultProperties.actions';

export const searchResultPropertiesStore = {
    namespaced: true,
    state,
    getters: { ...getters },
    actions: { ...actions },
    mutations: { ...mutations },
};
