import { state } from './cart.state';
import * as getters from './cart.getters';
import * as mutations from './cart.mutations';
import * as actions from './cart.actions';

export const cartStore = {
    namespaced: true,
    state,
    getters: { ...getters },
    actions: { ...actions },
    mutations: { ...mutations },
};
