














import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../../base/baseComponent';
import VueFilterIcon from './vueFilterIcon.vue';

@Component({
    components: { VueFilterIcon },
})
export default class VueSpecificationFilter extends BaseComponent {
    @Prop({ type: String })
    public matchcode: string;

    @Prop({ type: String })
    public name: string;

    public get possibleSpecificationValues() {
        return Object.values(this.models.searchResultProperties.availableFilters.specification).find(
            (spec) => spec.matchcode === this.matchcode
        )?.values as { [key: string]: string };
    }

    public get possibleSpecificationValuesSorted() {
        const specificationValues: { id: string; value: string }[] = [];
        for (const [id, value] of Object.entries(this.possibleSpecificationValues)) {
            specificationValues.push({ id, value });
        }
        if (this.allValuesStartWithNumbers(specificationValues.map((obj) => obj.value))) {
            return specificationValues.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }
        return specificationValues.sort((a, b) => a.value.localeCompare(b.value));
    }

    public allValuesStartWithNumbers(values: string[]) {
        values.forEach((value) => {
            if (isNaN(parseInt(value))) {
                return false;
            }
        });
        return true;
    }

    public selectSpecificationValue(id: string) {
        const parseId = parseInt(id);
        this.models.searchResultProperties.toggleBaseFilter({ id: parseId, baseFilter: 'specification' });
    }

    public specificationFilterIsSet(id: string) {
        const parseId = parseInt(id);
        return this.models.searchResultProperties.baseFilterIsSet({ id: parseId, baseFilter: 'specification' });
    }

    public show(): boolean {
        if (!Object.keys(this.possibleSpecificationValues).length) {
            return false;
        }

        if (Object.keys(this.possibleSpecificationValues).length > 1) {
            return true;
        }

        return this.specificationFilterIsSet(Object.keys(this.possibleSpecificationValues)[0]);
    }
}
