import { ISearchResultPropertiesState } from './searchResultPropertiesState.interface';

export const state = (): ISearchResultPropertiesState => {
    return {
        availableFilters: {
            brand: [],
            colour: [],
            material: [],
            size: [],
            gender: [],
            has_stock: [],
            fuel_type: [],
            specification: {},
        },
        currentPage: 1,
        currentFilters: {
            brand: [],
            colour: [],
            fuel_type: [],
            gender: [],
            has_stock: [],
            material: [],
            size: [],
            specification: [],
            search: '',
        },
        productIds: {},
        lastPage: 1,
        totalProducts: 0,
        categoryId: null,
        activeRequest: null,
        isNew: true,
        queryParamsString: null,
    };
};
