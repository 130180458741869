











import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../../base/baseComponent';

@Component
export default class VueFilterIcon extends BaseComponent {
    @Prop({
        type: Boolean,
    })
    isChecked: boolean;
}
