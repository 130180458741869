import { Store } from 'vuex';
import { EntityModel } from '../base/baseEntity.model';
import { IBaseState } from '../base/baseState.interface';
import { SpecificationRequestModel } from './specification.requestModel';
import { ISpecification } from './specification.interface';

export class SpecificationModel extends EntityModel<
    IBaseState<ISpecification>,
    typeof SpecificationRequestModel,
    ISpecification
> {
    constructor(store: Store<IBaseState<ISpecification>>) {
        super('specifications', store, SpecificationRequestModel);
    }

    public getBySpecificationGroup(id: number) {
        return this._store.getters[`${this._modelName}/getBySpecificationGroup`](id);
    }
}
