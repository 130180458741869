













































































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';
import { IProduct } from '../../store/product/product.interface';
import { ICategory } from '../../store/category/category.interface';
import VueBuySmall from './vueBuySmall.vue';

@Component({
    components: { VueBuySmall },
    })
export default class VueProductPreview extends BaseComponent {
    @Prop({
        type: Number,
    })
    productId: number;
    @Prop({
        type: Boolean,
    })
    demoMode: boolean;

    public get product(): IProduct {
        return this.models.product.getById(this.productId);
    }

    public get category(): ICategory {
        return this.models.category.getById(this.product?.category_id);
    }
}
