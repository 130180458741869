




import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';
import { ICartState } from '../../store/cart/cartState.interface';

@Component
export default class VueStoreFillCart extends BaseComponent {
    @Prop({
        type: Object,
    })
    cartState: ICartState;

    public created() {
        if (this.cartState) {
            this.models.cart.resetState(this.cartState);
        }
    }
}
