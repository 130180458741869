













import { Component, Watch } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';

@Component
export default class VueSearchInput extends BaseComponent {
    public searchTerm: string = null;

    public get currentSearch(): string {
        return this.models.searchResultProperties.currentFilters.search;
    }

    @Watch('currentSearch')
    public setSearchTerm(currentSearch: string): void {
        this.searchTerm = currentSearch;
    }

    submit(event: Event) {
        this.searchTerm = this.searchTerm.trim();

        if (!this.searchTerm) {
            event.preventDefault();
        }
    }
}
