



















import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';
import VueBrandFilter from './filter/vueBrandFilter.vue';
import VueGenderFilter from './filter/vueGenderFilter.vue';
import { IBrand } from '../../store/brand.interface';
import { IGender } from '../../store/gender.interface';
import VueSpecificationFilter from './filter/vueSpecificationFilter.vue';
import { IColour } from '../../store/colour.interface';
import VueColourFilter from './filter/vueColourFilter.vue';
import { IMaterial } from '../../store/material.interface';
import VueMaterialFilter from './filter/vueMaterialFilter.vue';
import { IFuelType } from '../../store/fuelType.interface';
import VueFuelTypeFilter from './filter/vueFuelTypeFilter.vue';
import { ISize } from '../../store/size.interface';
import VueSizeFilter from './filter/vueSizeFilter.vue';
import VueStockFilter from './filter/vueStockFilter.vue';

@Component({
    components: {
        VueSpecificationFilter,
        VueBrandFilter,
        VueGenderFilter,
        VueColourFilter,
        VueMaterialFilter,
        VueFuelTypeFilter,
        VueSizeFilter,
        VueStockFilter,
    },
})
export default class VueFilters extends BaseComponent {
    @Prop({ type: Array })
    public brands: IBrand[];
    @Prop({ type: Array })
    public genders: IGender[];
    @Prop({ type: Array })
    public colours: IColour[];
    @Prop({ type: Array })
    public materials: IMaterial[];
    @Prop({ type: Array })
    public fuelTypes: IFuelType[];
    @Prop({ type: Array })
    public sizes: ISize[];
}
