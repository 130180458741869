var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative mt-2 text-left text-gray-700 min-h-16"},[_c('editor',{attrs:{"name":_vm.name,"value":_vm.internValue,"api-key":"6shd46sowki9fg98un4sdjuru2amdjuj1u8xpwlqc9jmlggq","disabled":_vm.disabled,"id":_vm.id + '_editor',"init":{
            menubar: false,
            plugins: ['autoresize lists advlist link'],
            toolbar:
                'undo redo | h6 | bold italic | link unlink | alignleft aligncenter alignright alignjustify |bullist numlist | cut copy | removeformat',
            default_link_target: '_blank',
            content_style: [
                'h6 {font-size:18px; margin-block-start:0em;margin-block-end:0em} ',
                'p {margin-block-start:0em}',
                'ul {margin-block-start: 0em}' ],
        }},on:{"input":_vm.input}}),_vm._v(" "),_c('span',{ref:"tooltipp",staticClass:"input__label"},[_vm._v("\n        "+_vm._s(_vm.label)+_vm._s(_vm.required ? ' *' : '')+"\n        "),(_vm.tooltipp)?_c('em',{staticClass:"fas fa-info-circle"}):_vm._e()]),_vm._v(" "),(_vm.showError)?_c('span',{staticClass:"text-xs input__error",attrs:{"role":"alert"}},[_vm._v("\n        "+_vm._s(_vm.initialError || _vm.fieldControl.errors[0])+"\n    ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }