










import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../../base/baseComponent';
import { IGender } from '../../../store/gender.interface';
import VueFilterIcon from './vueFilterIcon.vue';

@Component({
    components: { VueFilterIcon },
})
export default class VueGenderFilter extends BaseComponent {
    @Prop({ type: Array })
    public genders: IGender[];

    public get possibleGenderIds() {
        return this.models.searchResultProperties.availableFilters.gender;
    }

    public get possibleGenders() {
        return this.genders.filter((gender) => this.possibleGenderIds.includes(gender.id));
    }

    public selectGender(id: number) {
        this.models.searchResultProperties.toggleBaseFilter({ id, baseFilter: 'gender' });
    }

    public genderFilterIsSet(id: number) {
        return this.models.searchResultProperties.baseFilterIsSet({ id, baseFilter: 'gender' });
    }
}
