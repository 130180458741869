








import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';
import { ICategory } from '../../store/category/category.interface';
import VueCategorySortItem from './vueCategorySortItem.vue';

@Component({
    components: { VueCategorySortItem },
})
export default class VueCategorySort extends BaseComponent {
    @Prop({
        type: Array,
    })
    categories: Array<ICategory>;
}
