
























































import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';
import { IModel } from '../../store/model.interface';
import vSelect from 'vue-select';
import { FieldControl } from '../../helpers/validation/fieldControl';
import { ValidationRule } from '../../helpers/validation/rules/validationRule';
import { IAddress } from '../../store/address.interface';

@Component({
    components: {
        vSelect,
    },
})
export default class VueSelectNetsuiteDeliveryAddresses extends BaseComponent {
    @Prop({
        type: [Object, String],
    })
    value: IModel | string;

    @Prop({
        type: Object,
    })
    initValue: IModel;

    @Prop({
        type: Boolean,
    })
    isBlade: boolean;

    public input(ev: any) {
        console.log(ev);
        this.internalValue = ev;
        this.initialError = null;
        this.validate();
        this.$emit('input', ev);
        this.blur(ev);
    }

    @Prop({
        type: String,
        default: 'id',
    })
    optionKey: string;

    @Prop({
        type: String,
        default: '',
    })
    name: string;

    @Prop({
        type: String,
        default: '',
    })
    label: string;

    @Prop({
        type: Array,
    })
    searchKeys: string[];

    @Prop({
        type: Boolean,
        default: false,
    })
    clearable: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    disabled: boolean;

    @Prop({
        type: Array,
        default: (): [] => {
            return [];
        },
    })
    options: Array<IModel>;

    @Prop({
        type: Boolean,
        default: false,
    })
    searchable: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    required: boolean;

    @Prop({
        type: Function,
    })
    selectable: (option: any) => boolean;

    @Prop({
        type: String,
    })
    initError: string;

    public internalValue: IModel | string = null;
    private fieldControl: FieldControl;
    public isDirty = false;
    private initialError: string = null;
    private isTouched = false;

    public created() {
        this.initialError = this.initError;

        if (this.initValue) {
            this.internalValue = this.initValue;
        } else {
            this.internalValue = this.value;
        }

        this.fieldControl = new FieldControl(this.name);
        if (this.required) {
            this.fieldControl.addValidationRule(ValidationRule.required);
        }
        this.validate();
    }

    private validate() {
        this.fieldControl.validate(this.internalValue);
    }

    public get isValid() {
        return this.fieldControl.isValid;
    }

    public beforeUpdate() {
        if (this.isBlade) {
            return;
        }
        this.internalValue = this.calcValue;
        this.validate();
    }

    get showError() {
        return !!this.initialError || (!this.fieldControl.isValid && this.isTouched);
    }

    get calcValue() {
        return this.value;
    }

    showOption(address: IAddress) {
        let line = '';
        let started = false;

        if (address.name) {
            line += address.name;
            started = true;
            if (started) line += ', ';
        }
        if (address.attention) {
            line += address.attention;
            started = true;
            if (started) line += ', ';
        }
        if (address.street) {
            line += address.street;
            started = true;
            if (started) line += ', ';
        }
        if (address.street2) {
            line += address.street2;
            started = true;
            if (started) line += ', ';
        }
        if (address.zip) {
            line += address.zip;
            started = true;
            if (started) line += ', ';
        }
        if (address.city) {
            line += address.city;
        }
        return line;
    }

    @Emit()
    search(search: any, loading: any) {
        return { search, loading };
    }

    @Emit()
    blur(ev: any) {
        this.isTouched = true;
        this.validate();
        return ev;
    }
}
