import { Store } from 'vuex';
import { EntityModel } from '../base/baseEntity.model';
import { IBaseState } from '../base/baseState.interface';
import { ProductRequestModel } from './product.requestModel';
import { IProduct } from './product.interface';

export class ProductModel extends EntityModel<IBaseState<IProduct>, typeof ProductRequestModel, IProduct> {
    constructor(store: Store<IBaseState<IProduct>>) {
        super('product', store, ProductRequestModel);
    }
}
