



























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';
import { ICartItem } from '../../store/cart/cartItem.interface';
import VueForm from '../form/vueForm.vue';
import Swal from 'sweetalert2';

@Component({
    components: { VueForm },
})
export default class VueCartPosition extends BaseComponent {
    @Prop({
        type: Object,
    })
    position: ICartItem;

    @Prop({
        type: Boolean,
    })
    disabled: boolean;

    @Prop({
        type: Boolean,
    })
    demoMode: boolean;

    public quantity = 1;

    @Watch('position.quantity')
    onStoreQuantityChange(val: number) {
        this.quantity = val;
    }

    public created() {
        this.quantity = this.position.quantity;
    }

    public enter(e: Event) {
        const target = <HTMLInputElement>e.target;
        target.blur();
        e.preventDefault();
    }

    public increaseQuantity() {
        this.models.cart
            .setQuantity({
                cartItem: this.position,
                quantity: this.position.quantity + this.product.packing_unit,
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    text: this.lang('errors.position.quantity_too_high'),
                });
            });
    }

    public decreaseQuantity() {
        this.models.cart.setQuantity({
            cartItem: this.position,
            quantity: this.position.quantity - this.product.packing_unit,
        });
    }

    public sumCart() {
        return this.position.quantity * this.position.net_price;
    }

    public deletePosition() {
        this.models.cart.removeItem(this.position);
    }

    public changeQuantity(changeEvent: Event) {
        const target = <HTMLInputElement>changeEvent.target;
        this.models.cart
            .setQuantity({
                cartItem: this.position,
                quantity: parseInt(target.value, 10) || 0,
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    text: this.lang('errors.position.quantity_too_high'),
                });
            });
    }

    public get product() {
        return this.models.product.getById(this.position.product_id);
    }
}
