







import { Component } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';

@Component
export default class VueSpinner extends BaseComponent {}
