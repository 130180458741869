
















import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../../base/baseComponent';
import VueFilterIcon from './vueFilterIcon.vue';
import { IColour } from '../../../store/colour.interface';

@Component({
    components: { VueFilterIcon },
})
export default class VueColourFilter extends BaseComponent {
    @Prop({ type: Array })
    public colours: IColour[];

    public get possibleColourIds() {
        return this.models.searchResultProperties.availableFilters.colour;
    }

    public get possibleColours() {
        return this.colours.filter((colour) => this.possibleColourIds.includes(colour.id));
    }

    public selectColour(id: number) {
        this.models.searchResultProperties.toggleBaseFilter({ id, baseFilter: 'colour' });
    }

    public colourFilterIsSet(id: number) {
        return this.models.searchResultProperties.baseFilterIsSet({ id, baseFilter: 'colour' });
    }

    public get currentFilters() {
        return this.models.searchResultProperties.currentFilters;
    }
}
