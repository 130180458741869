import { ActionContext } from 'vuex';
import { ICartState } from './cartState.interface';
import { IProduct } from '../product/product.interface';
import { ICartItem } from './cartItem.interface';
import { CartRequestModel } from './cart.requestModel';

export const resetState = (context: ActionContext<ICartState, any>, newCartState: ICartState) => {
    return context.commit('resetState', newCartState);
};

export const removeItem = async (context: ActionContext<ICartState, any>, cartItem: ICartItem) => {
    context.commit('removeItem', cartItem);
    const RequestModel = context.getters.requestModel as typeof CartRequestModel;
    const newCartState = await RequestModel.removeItem(cartItem);
    return context.commit('resetState', newCartState);
};

export const reloadState = async (context: ActionContext<ICartState, any>) => {
    const RequestModel = context.getters.requestModel as typeof CartRequestModel;
    const newCartState = await RequestModel.getState();
    return context.commit('resetState', newCartState);
};

export const setQuantity = async (
    context: ActionContext<ICartState, any>,
    payload: { cartItem: ICartItem; quantity: number }
) => {
    const product = context.rootGetters['product/byId'](payload.cartItem.product_id);
    if (product && product.is_sale && product.stock_quantity < payload.quantity) {
        throw new Error('nicht genügend an Lager');
    }

    context.commit('setQuantity', payload);
    const RequestModel = context.getters.requestModel as typeof CartRequestModel;
    try {
        const newCartState = await RequestModel.setQuantity(payload.cartItem);
        return context.commit('resetState', newCartState);
    } catch (e) {
        console.log('catch');
        return context.dispatch('reloadState');
    }
};

export const addItem = async (
    context: ActionContext<ICartState, any>,
    payload: { product: IProduct; quantity: number }
) => {
    if (payload.product.is_sale && !payload.product.stock_quantity) {
        throw new Error('nicht genügend an Lager');
    }

    context.commit('addItem', payload);
    const RequestModel = context.getters.requestModel as typeof CartRequestModel;

    try {
        const newCartState = await RequestModel.post(context.getters.getOrderId, payload.product, payload.quantity);
        return context.commit('resetState', newCartState);
    } catch (e) {
        context.dispatch('reloadState');
        throw e;
    }
};
