import { baseFilters, BaseFilters, ISearchResultPropertiesState } from './searchResultPropertiesState.interface';
import { ISearchResultPropertiesBackend } from './searchResultPropertiesBackend.interface';
import Vue from 'vue';
import { IProduct } from '../product/product.interface';

export const update = (state: ISearchResultPropertiesState, payload: ISearchResultPropertiesBackend) => {
    Vue.set(
        state.productIds,
        payload.currentPage,
        payload.products.map((product) => product.id)
    );
    state.availableFilters = payload.availableFilters;
    state.categoryId = payload.categoryId;

    state.currentFilters = { ...state.currentFilters, ...payload.currentFilters };

    state.currentPage = payload.currentPage;
    state.totalProducts = payload.totalProducts;
    state.lastPage = payload.lastPage;
    state.isNew = false;
};

export const addPage = (state: ISearchResultPropertiesState, payload: ISearchResultPropertiesBackend) => {
    Vue.set(
        state.productIds,
        payload.currentPage,
        payload.products.map((product) => product.id)
    );
};

export const toggleBaseFilter = (
    state: ISearchResultPropertiesState,
    payload: {
        baseFilter: BaseFilters;
        id: number;
    }
) => {
    if (state.currentFilters[payload.baseFilter].includes(payload.id)) {
        const index = state.currentFilters[payload.baseFilter].indexOf(payload.id);
        state.currentFilters[payload.baseFilter].splice(index, 1);
        return;
    }
    state.currentFilters[payload.baseFilter].push(payload.id);
};

export const resetResults = (state: ISearchResultPropertiesState) => {
    state.currentPage = 1;
    state.lastPage = null;
    state.productIds = {};
    state.totalProducts = null;
};

export const setCurrentPage = (state: ISearchResultPropertiesState, payload: number) => {
    state.currentPage = payload;
};

export const setQueryParamsOnUrl = (state: ISearchResultPropertiesState) => {
    const activePath = window.location.pathname;
    const activeOrigin = window.location.origin;
    const url = new URL(activeOrigin + activePath);

    for (const baseFilter of baseFilters) {
        state.currentFilters[baseFilter].forEach((valueId) => {
            url.searchParams.append(baseFilter + '[]', valueId.toString(10));
        });
    }

    if (state.currentFilters.search) {
        url.searchParams.append('search', state.currentFilters.search);
    }

    if (state.currentPage > 1) {
        url.searchParams.append('page', state.currentPage.toString());
    }

    state.queryParamsString = url.search;
    window.history.replaceState({}, '', url.search || activePath);
};

export const storeToStorage = (state: ISearchResultPropertiesState, products: IProduct[]) => {
    const stateBackend: ISearchResultPropertiesBackend = {
        lastPage: state.lastPage,
        categoryId: state.categoryId,
        totalProducts: state.totalProducts,
        currentPage: state.currentPage,
        availableFilters: state.availableFilters,
        currentFilters: state.currentFilters,
        products: [...products],
    };
    window.localStorage.setItem('searchResultStore', JSON.stringify(stateBackend));
};
