import './bootstrap';

import { Vue } from 'vue-property-decorator';
import Vuex from 'vuex';

import { flare } from '@flareapp/flare-client';
import { flareVue } from '@flareapp/flare-vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import VueInput from './components/form/vueInput.vue';
import VueSelect from './components/form/vueSelect.vue';
import VueSelectNetsuiteDeliveryAddresses from './components/cart/vueSelectNetsuiteDeliveryAddresses.vue';
import VueForm from './components/form/vueForm.vue';
import VueCheckbox from './components/form/vueCheckbox.vue';
import VueCategoryList from './components/product/vueCategoryList.vue';
import VueFilters from './components/product/vueFilters.vue';
import VueProductPreviewCollection from './components/product/vueProductPreviewCollection.vue';
import VueSessionSweetalert from './components/base/vueSessionSweetalert.vue';

import VueStoreFill from './components/store/vueStoreFill.vue';
import VueStoreFillSearchResults from './components/store/vueStoreFillSearchResults.vue';
import VueEditor from './components/form/vueEditor.vue';
import VueCategorySort from './components/category/vueCategorySort.vue';
import VueBuyLarge from './components/product/vueBuyLarge.vue';
import VueStoreFillCart from './components/store/vueStoreFillCart.vue';
import VueBuySmall from './components/product/vueBuySmall.vue';
import VueCartPositions from './components/cart/vueCartPositions.vue';
import VueCartPreview from './components/cart/vueCartPreview.vue';
import VueSearchInput from './components/search/vueSearchInput.vue';
import VueOverlay from './components/product/vueOverlay.vue';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { MediaLibraryAttachment as VueMediaLibraryAttachment } from '../../vendor/spatie/laravel-medialibrary-pro/resources/js/media-library-pro-vue2-attachment/dist';
import { categoryStore } from './store/category/category.store';
import { productStore } from './store/product/product.store';
import { searchResultPropertiesStore } from './store/searchResultProperties/searchResultProperties.store';

import Editor from '@tinymce/tinymce-vue';
import { cartStore } from './store/cart/cart.store';
import { specificationStore } from './store/Specification/specification.store';
import { specificationGroupStore } from './store/SpecificationGroup/specificationGroup.store';
import 'vueperslides/dist/vueperslides.css';
import './custom.css';

// @ts-ignore
import { VueperSlides, VueperSlide } from 'vueperslides';

library.add(faUserSecret);
library.add(faCheck);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('editor', Editor);
Vue.use(Vuex);

if (process.env.NODE_ENV === 'production') {
    flare.light('ooxOe2fRCZzerOgLY8kASfTcUwInqrfT');
}

Vue.use(flareVue);

const store = new Vuex.Store({
    strict: true,
    modules: {
        category: categoryStore,
        product: productStore,
        searchResultProperties: searchResultPropertiesStore,
        cart: cartStore,
        specification: specificationStore,
        specificationGroup: specificationGroupStore,
    },
    // plugins: [ createPersistedState( { paths: [ 'searchResultProperties' ] } ) ]
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const app = new Vue({
    el: '#app',
    components: {
        VueInput,
        VueSelect,
        VueForm,
        VueCategoryList,
        VueFilters,
        VueStoreFill,
        VueProductPreviewCollection,
        VueStoreFillSearchResults,
        VueMediaLibraryAttachment,
        VueSessionSweetalert,
        VueCheckbox,
        VueEditor,
        VueCategorySort,
        VueBuyLarge,
        VueStoreFillCart,
        VueBuySmall,
        VueCartPositions,
        VueCartPreview,
        VueSearchInput,
        VueOverlay,
        VueSelectNetsuiteDeliveryAddresses,
        VueperSlides,
        VueperSlide,
    },
    store,
});
