










import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../../base/baseComponent';
import VueFilterIcon from './vueFilterIcon.vue';
import { ISize } from '../../../store/size.interface';

@Component({
    components: { VueFilterIcon },
})
export default class VueSizeFilter extends BaseComponent {
    @Prop({ type: Array })
    public sizes: ISize[];

    public get possibleSizeIds() {
        return this.models.searchResultProperties.availableFilters.size;
    }

    public get possibleSizes() {
        return this.sizes.filter((size) => this.possibleSizeIds.includes(size.id));
    }

    public selectSize(id: number) {
        this.models.searchResultProperties.toggleBaseFilter({ id, baseFilter: 'size' });
    }

    public sizeFilterIsSet(id: number) {
        return this.models.searchResultProperties.baseFilterIsSet({ id, baseFilter: 'size' });
    }
}
