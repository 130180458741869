import * as trans from '../vue-translations.json';

class Translation {
    private _langSlags: any = {
        'de-CH': 'de-CH',
        en: 'en-GB',
        fr: 'fr-CH',
        it: 'it-CH',
    };

    private _langSlag = 'de-CH';
    private _translationObject: any = trans;

    public initLang() {
        this.setLangSlag();
    }

    public get(name: string, attributes?: { [key: string]: string }) {
        let translation = this.findInObject(this.getPath(name), this._translationObject);

        if (translation) {
            translation = this.replaceAttributes(translation, attributes);
            return translation;
        }

        translation = this.findInObject(this.getPath(name, true), this._translationObject);

        if (translation) {
            translation = this.replaceAttributes(translation, attributes);
            return translation;
        }

        return name;
    }

    private setLangSlag() {
        const langSlag = document.querySelector("meta[name='language']")?.getAttribute('content');

        console.log(langSlag);

        if (!langSlag) {
            return;
        }

        if (!this._langSlags[langSlag]) {
            return;
        }

        this._langSlag = this._langSlags[langSlag];
    }

    private getPath(name: string, def = false) {
        const path = name.split('.');

        if (def) {
            path[0] = 'de-CH.' + path[0];
        } else {
            path[0] = this._langSlag + '.' + path[0];
        }
        return path;
    }

    private findInObject(path: string[], object: any): string | undefined {
        // console.log( "path :>> ", path );
        //  console.log( "object :>> ", object );
        if (!path || path.length === 0 || !object) {
            return;
        }

        const clue = path.shift();
        if (clue === undefined) {
            return;
        }
        const nextObj = object[clue];

        if (typeof nextObj === 'string' && path.length === 0) {
            return nextObj;
        }

        return this.findInObject(path, nextObj);
    }

    private replaceAttributes(translation: string, attributes: { [key: string]: string | undefined }): string {
        if (!attributes) {
            return translation;
        }
        for (const attributeName in attributes) {
            if (Object.prototype.hasOwnProperty.call(attributes, attributeName)) {
                const transString = attributes[attributeName];

                const translatedAttribute = this.get(transString);
                translation = translation.replace(':' + attributeName, translatedAttribute);
            }
        }

        return translation;
    }
}

export const TransLation = new Translation();
